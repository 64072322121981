import {isAxiosError} from 'axios';

import { i18n } from 'i18n';
import { BulkResponse, Exception, ExceptionDetail, MultiResponseError } from './types';

export const checkBulkResponse = (results: BulkResponse) => {
    const errors = results?.responses?.filter((result) => result.status > 299 && result.error);

    if (errors && errors.length) {
        throw new MultiResponseError('One or more errors occured', { responses: errors });
    }
};

export const getExceptionDetails = (error?: unknown): ExceptionDetail | null => {
    let details: string[] = [];

    if (!error) {
        return null;
    }

    if (typeof error === 'string') {
        return {
            message: error
        };
    }

    if (error instanceof MultiResponseError) {
        return {
            message: error.message,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            details: error.Details?.responses?.filter((response) => response.error).map((response) => response.error!)
        };
    }

    if (isAxiosError(error)) {
        if (error.response?.data) {
            if ('exceptionDetails' in error.response.data) {
                if (Array.isArray(error.response.data.exceptionDetails)) {
                    details = error.response.data.exceptionDetails.map(
                        (exceptionDetail: Exception) => exceptionDetail.message
                    );
                } else if (error.response.data.exceptionDetails satisfies Exception) {
                    details = [error.response.data.exceptionDetails.message];
                }
            }
            return {
                message: error.response.data.detail,
                details
            };
        }
    }

    if (typeof error === 'object') {
        if ('errors' in error && typeof error.errors === 'object' && error.errors) {
            const details = Object.entries(error.errors).map((entry) => ({
                message: entry[1],
                property: entry[0]
            }));
            return {
                message: i18n.t('errorsOccurred'),
                details
            };
        }
        if ('detail' in error && error.detail) {
            if (typeof error.detail === 'string' && error.detail) {
                if ('status' in error && typeof error.status === 'number' && error.status === 403) {
                    return {
                        message: i18n.t('insufficientlyPrivileged'),
                        details: [error.detail]
                    };
                }

                return {
                    message: error.detail
                };
            }
            if (typeof error.detail === 'object') {
                return {
                    message: JSON.stringify(error.detail, null, 2)
                };
            }
        }
    }

    return { message: 'Unknown error occured' };
};
