import { Button, TextInput } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { Icon } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetApplications } from 'api/v3/application/application';
import { listQueryDefault } from 'app/queryDefaults';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { CreateItemModal } from 'shared/components';
import { BaseLayout } from 'shared/components/baseLayout/BaseLayout';
import { FlexList } from 'shared/components/FlexList';
import { Placeholder } from 'shared/components/placeholder';
import { ApplicationCard } from './ApplicationCard';
import { ApplicationCreate } from './ApplicationCreate';
import applicationsEmpty from './assets/applications_empty.svg';

const AddApplicationButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<Icon icon="add" size="2xl" />} onClick={() => onClick()}>
            {t('createItem', { itemType: t('application') })}
        </Button>
    );
};

const NoApplicationsPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create');
    return (
        <Placeholder
            action={
                canCreate && (
                    <CreateItemModal
                        AddButton={AddApplicationButton}
                        AddDialogType={ApplicationCreate}
                        title={t('createItem', { itemType: t('application') })}
                    />
                )
            }
            description={canCreate && t('noApplicationsDescription')}
            picture={applicationsEmpty}
            text={t('noApplications')}
        />
    );
};

export const Applications = () => {
    const { t } = useTranslation();
    const { data: applications, isLoading, queryKey, error } = useGetApplications({ includePermissions: true, $top: 100 }, listQueryDefault);
    const queryClient = useQueryClient();
    const canCreate = usePermissions('Create', 'Application', applications);
    const [searchValue, setSearchValue] = useState('');

    const applicationList = applications
        ? searchValue
            ? applications.data.filter((application) => application.data.displayName.toLowerCase().includes(searchValue.toLowerCase()))
            : applications.data
        : [];

    return (
        <BaseLayout background="gray" showSidebar={false}>
            <PermissionProvider context="Application" item={applications}>
                <FlexList
                    AddDialogType={canCreate ? ApplicationCreate : undefined}
                    addDialogTitle={t('createItem', { itemType: t('application') })}
                    areFiltersSet={!!searchValue}
                    error={error}
                    header={t('application')}
                    isLoading={isLoading}
                    placeholder={<NoApplicationsPlaceholder />}
                    onClearFilters={() => setSearchValue('')}
                >
                    <FlexList.Filter>
                        <TextInput
                            className="w-72"
                            placeholder={t('search')}
                            value={searchValue}
                            onChange={(event) => setSearchValue(event.currentTarget.value)}
                        />
                    </FlexList.Filter>
                    {applicationList.map((applicationApiResponse) => (
                        <PermissionProvider key={applicationApiResponse.data.id} context="Application" item={applicationApiResponse}>
                            <ApplicationCard item={applicationApiResponse} onItemDelete={() => queryClient.invalidateQueries({ queryKey })} />
                        </PermissionProvider>
                    ))}
                </FlexList>
            </PermissionProvider>
        </BaseLayout>
    );
};
