import { ActionIcon, Menu, Tooltip } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TenantLoginProviderType, UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { ResetPasswordModal } from './ResetPasswordModal';

export const ResetPasswordAction = (user: BasicUserModelApiResponse, index: number, rowActionVariant: RowActionVariant) => {
    const { t } = useTranslation();
    const canResetPassword = usePermissions('ResetPassword', 'User', user);
    const [modalOpen, setModalOpen] = useState(false);

    if (user.data.state !== UserState.Activated || user.data.loginProviderType !== TenantLoginProviderType.Local) {
        return null;
    }

    return (
        canResetPassword && (
            <>
                {modalOpen && <ResetPasswordModal user={user} onClose={() => setModalOpen(false)} />}

                {rowActionVariant === RowActionVariant.DEFAULT ? (
                    <Tooltip label={t('resetPassword')}>
                        <ActionIcon onClick={() => setModalOpen(true)}>
                            <Icon icon="passkey" size="xl" />
                        </ActionIcon>
                    </Tooltip>
                ) : (
                    <Menu.Item leftSection={<Icon icon="passkey" size="xl" />} onClick={() => setModalOpen(true)}>
                        {t('resetPassword')}
                    </Menu.Item>
                )}
            </>
        )
    );
};
