import { Modal, Button, Text, Flex, Stack, TextInput } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { toastNotifications, variants } from '@uag/react-core';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { TenantLoginProviderType, UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useResetPassword, getSearchUsersQueryKey } from 'api/v3/user/user';
import { CopyToClipboard } from 'shared/components';

type resetPasswordModalType = {
    user: BasicUserModelApiResponse;
    onClose: () => void;
};

export const ResetPasswordModal = ({ user, onClose }: resetPasswordModalType) => {
    const { t } = useTranslation();
    const { mutateAsync: resetPassword } = useResetPassword();
    const queryClient = useQueryClient();
    const id = useId();

    if (user.data.state !== UserState.Activated || user.data.loginProviderType !== TenantLoginProviderType.Local) {
        return null;
    }

    const handleResetPassword = async (event: MouseEvent<HTMLButtonElement>) => {
        onClose();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        toastNotifications.loading({
            id,
            message: t('willBe', {
                type: t('user'),
                name: `${user.data.displayName} ${t('password').toLowerCase()}`,
                action: t('reset')
            })
        });
        try {
            const result = await resetPassword({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            const responseUrl = 'data' in result && typeof result.data === 'string' && result.data ? result.data : undefined;

            toastNotifications.sucess({
                message: (
                    <Stack>
                        <Text>
                            {t('wasSuccessful', {
                                type: t('user'),
                                name: user.data.displayName,
                                action: t('resetPassword')
                            })}
                        </Text>
                        {responseUrl && (
                            <Flex gap={6} ta="center">
                                <TextInput value={responseUrl} w="100%" readOnly />
                                <CopyToClipboard textToCopy={responseUrl} />
                            </Flex>
                        )}
                    </Stack>
                ),
                autoClose: !responseUrl
            });
        } catch {
            toastNotifications.error({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('resetPassword')
                })
            });
        } finally {
            toastNotifications.hide(id);
        }
    };

    return (
        <Modal opened={true} title={`${t('resetPassword')}?`} onClose={onClose}>
            <div className="flex flex-col gap-5">
                <p className="text-base text-text-ultra-light">{t('confirmResetPassword', { email: user.data.email })}</p>
                <div className="flex justify-end gap-1">
                    <Button variant={variants.button.secondary} onClick={onClose}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleResetPassword}>{t('resetPassword')}</Button>
                </div>
            </div>
        </Modal>
    );
};
