import { Button, Divider, Flex, Group, Stack, Text, Textarea, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { Icon } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { InviteUserModel, TenantLoginProviderType } from 'api/v3/models';
import { useGetLoginProviders } from 'api/v3/tenant/tenant';
import { getSearchUsersQueryKey, useInviteUser } from 'api/v3/user/user';
import { i18n } from 'i18n';
import { ExtendedTextInput, Select } from 'shared/components/inputs';

export const createUserSchema = z.object({
    firstName: z.string().min(1, { message: i18n.t('fieldRequired') }),
    lastName: z.string().min(1, { message: i18n.t('fieldRequired') }),
    userName: z.string().min(1, { message: i18n.t('fieldRequired') }),
    domain: z.string().min(1, { message: i18n.t('fieldRequired') }),
});

export type CreateUserFormValues = z.infer<typeof createUserSchema>;

export const CreateUser = () => {
    const [responseUrl, setResponseUrl] = useState('');

    const { mutateAsync: createUser, isPending: loadingCreateUser } = useInviteUser();
    const queryClient = useQueryClient();

    const handleCreate = async (formValues: CreateUserFormValues) => {
        const { firstName, lastName, userName, domain } = formValues;
        const payload: InviteUserModel = {
            firstName,
            lastName,
            userName: `${userName}@${domain}`,
        };
        const url = await createUser({ data: payload });
        setResponseUrl(url?.data ?? '');
        await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });
    };

    return responseUrl ? <ResponseUrl responseUrl={responseUrl} /> : <CreateUserForm loading={loadingCreateUser} onCreate={handleCreate} />;
};

type ResponseUrlProps = {
    responseUrl: string;
};

const ResponseUrl = ({ responseUrl }: ResponseUrlProps) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(responseUrl);
        if (!isCopied) {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 5000);
        }
    };

    return (
        <Flex gap="xl">
            <Stack w="100%">
                <Title order={4}>{t('shareLink')}</Title>
                <Textarea minRows={5} value={responseUrl} readOnly />
                <Button disabled={isCopied} leftSection={<Icon icon="content_copy" />} mt="auto" onClick={handleCopyToClipboard}>
                    {t('copyLink')}
                </Button>
            </Stack>
            <Divider orientation="vertical" size="sm" />
            <Stack w="100%">
                <Title order={4}>{t('completeProfile')}</Title>
                <Stack px="lg">
                    <Group gap="xs">
                        <Icon icon="check_box" />
                        <Text>{t('password')}</Text>
                    </Group>
                    <Group gap="xs">
                        <Icon icon="check_box" />
                        <Text>{t('language')}</Text>
                    </Group>
                    <Group gap="xs">
                        <Icon icon="check_box" />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text>. . .</Text>
                    </Group>
                </Stack>
                <Button component="a" href={`${responseUrl}&skipAutomaticLogin=true`} leftSection={<Icon icon="open_in_new" />} mt="auto">
                    {t('completeProfile')}
                </Button>
            </Stack>
        </Flex>
    );
};

type CreateUserFormProps = {
    onCreate: (formValues: CreateUserFormValues) => Promise<void>;
    loading?: boolean;
};

const CreateUserForm = ({ onCreate: handleCreate, loading = false }: CreateUserFormProps) => {
    const { t } = useTranslation();
    const form = useForm<CreateUserFormValues>({
        initialValues: { firstName: '', lastName: '', userName: '', domain: '' },
        validate: zodResolver(createUserSchema),
    });
    const [previousFirstName, setPreviousFirstName] = useState('');
    const [previousLastName, setPreviousLastName] = useState('');

    const { data: loginProviders, isLoading: areLoginProvidersLoading } = useGetLoginProviders();

    if (!form.isTouched('userName') && (form.values.firstName !== previousFirstName || form.values.lastName !== previousLastName)) {
        form.setValues({ userName: [form.values.firstName, form.values.lastName].join('.') });
        setPreviousFirstName(form.values.firstName);
        setPreviousLastName(form.values.lastName);
    }

    const domainOptions = loginProviders
        ? loginProviders.data
              .filter((loginProviders) => loginProviders.data.type === TenantLoginProviderType.Local)
              .map((loginProviders) => loginProviders.data.loginDomains)
              .flat()
        : [];

    return (
        <form onSubmit={form.onSubmit(handleCreate)}>
            <Stack>
                <ExtendedTextInput form={form} label={t('firstName')} placeholder={t('firstNamePlaceholder')} propertyPath="firstName" />
                <ExtendedTextInput form={form} label={t('lastName')} placeholder={t('lastNamePlaceholder')} propertyPath="lastName" />
                <Flex align="end" gap="sm">
                    <ExtendedTextInput
                        form={form}
                        label={t('username')}
                        placeholder={t('usernamePlaceholder')}
                        propertyPath="userName"
                        style={{ flex: 1 }}
                    />
                    <Text mb={6}>@</Text>
                    <Select
                        data={domainOptions}
                        disabled={!loginProviders || areLoginProvidersLoading}
                        form={form}
                        placeholder={t('selectPlaceholder')}
                        propertyPath="domain"
                        style={{ flex: 1 }}
                    />
                </Flex>
                <Button loading={loading} ml="auto" type="submit">
                    {t('createItem', { itemType: t('user') })}
                </Button>
            </Stack>
        </form>
    );
};
