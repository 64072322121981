import { Title } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
    BasicApiResourceRoleModelApiResponse,
    BasicApiResourceScopeModelApiResponse,
    BasicClientModelApiResponse,
    DetailApiResourceModel
} from 'api/v3/models';
import { i18n } from 'i18n';
import { scopeRoleNameRegex, apiNameRegex, guidRegex } from 'modules/shared/regex';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { ApiFormProvider, ApiFormValues, RolesFormType, ScopesFormType, TokenExchangeClientsFormType, useApiForm } from './ApiFormProvider';
import { BasicInformation } from './forms/BasicInformation';
import { Roles } from './forms/Roles';
import { Scopes } from './forms/Scopes';
import { TokenExchangeClients } from './forms/TokenExchangeClients';

type Props = {
    api: DetailApiResourceModel;
    scopes: BasicApiResourceScopeModelApiResponse[];
    roles: BasicApiResourceRoleModelApiResponse[];
    tokenExchangeClients: BasicClientModelApiResponse[];
    variant: DetailFormVariant;
    onSubmit?: (formValues: ApiFormValues) => Promise<void>;
    title?: string;
};

function getFormScopes(scopes: BasicApiResourceScopeModelApiResponse[]): ScopesFormType[] {
    return scopes.map((s) => ({
        key: randomId(),
        isEditable: false,
        isRemoved: false,
        ...s.data
    }));
}

function getFormTokenExchangeClients(clients: BasicClientModelApiResponse[]): TokenExchangeClientsFormType[] {
    return clients.map((s) => ({
        key: randomId(),
        isEditable: false,
        isRemoved: false,
        isNew: false,
        ...s.data
    }));
}

function getFormRoles(roles: BasicApiResourceRoleModelApiResponse[]): RolesFormType[] {
    return roles.map((role) => ({ key: randomId(), isEditable: false, isRemoved: false, ...role.data }));
}

function validateScopeRole(entry: { id?: string; name: string }, ctx: z.RefinementCtx) {
    if (!entry.id && !scopeRoleNameRegex.test(entry.name)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('invalidFormat'),
            path: ['name']
        });
    }
}

const schema = z.object({
    api: z
        .object({
            id: z.string().nullish(),
            name: z.string().min(1, { message: i18n.t('fieldRequired') }),
            displayName: z.string().min(1, { message: i18n.t('fieldRequired') }),
            includeGroupsInToken: z.boolean()
        })
        .superRefine((entry, ctx) => {
            if (!entry.id && !apiNameRegex.test(entry.name) && !guidRegex.test(entry.name)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('apiIdentifierValidation'),
                    path: ['name']
                });
            }
        }),
    scopes: z
        .object({
            id: z.string().optional(),
            name: z.string().min(1, { message: i18n.t('fieldRequired') })
        })
        .superRefine((v, ctx) => {
            validateScopeRole(v, ctx);
        })
        .array()
        .nonempty(),
    roles: z
        .object({
            id: z.string().optional(),
            name: z.string().min(1, { message: i18n.t('fieldRequired') })
        })
        .superRefine((v, ctx) => {
            validateScopeRole(v, ctx);
        })
        .array(),
    tokenExchangeClients: z
        .object({
            id: z.string().min(1, { message: i18n.t('fieldRequired') }),
            name: z.string().min(1, { message: i18n.t('fieldRequired') })
        })
        .array()
});

export const ApiForm = ({ api, scopes, roles, tokenExchangeClients, title, variant, onSubmit }: Props) => {
    const { t } = useTranslation();

    const form = useApiForm({
        initialValues: {
            api: {
                ...api,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                applicationId: api.applicationId!,
                enabled: api.enabled
            },
            scopes: getFormScopes(scopes),
            roles: getFormRoles(roles),
            tokenExchangeClients: getFormTokenExchangeClients(tokenExchangeClients)
        }
    });

    const [previousApi, setPreviousApi] = useState(api);
    const [previousScopes, setPreviousScopes] = useState(scopes);
    const [previousRoles, setPreviousRoles] = useState(roles);
    const [previousClients, setPreviousClients] = useState(tokenExchangeClients);

    if (previousApi !== api || previousScopes !== scopes || previousRoles !== roles || previousClients !== tokenExchangeClients) {
        setPreviousApi(api);
        setPreviousScopes(scopes);
        setPreviousRoles(roles);
        setPreviousClients(tokenExchangeClients);

        form.resetDirty();
    }

    const handleReset = () => {
        form.reset();
    };

    const handleUpdate = async () => {
        onSubmit && (await onSubmit(form.values));
    };

    return (
        <ApiFormProvider form={form}>
            <DetailForm
                form={form}
                title={title}
                validationSchema={schema}
                variant={variant}
                onConfirm={onSubmit ? handleUpdate : undefined}
                onReset={handleReset}
            >
                <BasicInformation label={t('basicInformation')} name="basicInformation" readOnly={!onSubmit} schemaPath="api" variant={variant} />
                <Scopes label={t('scopes')} name="scopes" schemaPath="scopes" variant={variant} />
                <Roles label={t('roles')} name="roles" schemaPath="roles" variant={variant} />
                <TokenExchangeClients
                    header={<Title order={4}>{t('enabledClients')}</Title>}
                    label={t('tokenExchangeClients')}
                    name="tokenEchangeClients"
                    schemaPath="tokenExchangeClients"
                    variant={variant}
                />
            </DetailForm>
        </ApiFormProvider>
    );
};
