import { SelectProps as ExternalSelectProps } from '@mantine/core';
import { Select as ExternalSelect } from '@uag/react-core';
import { forwardRef, RefAttributes } from 'react';

import { IMantineFormControl } from 'shared/components/inputs/ExtendedInputControls';
import { InputLabel, InputLabelProps } from 'shared/components/inputs/InputLabel';

export type SelectProps = IMantineFormControl &
    InputLabelProps &
    Omit<ExternalSelectProps, 'form'> &
    RefAttributes<HTMLInputElement>;

export const Select = forwardRef<HTMLInputElement, SelectProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, optional, ...others }: SelectProps, ref) => {
        const props = form.getInputProps(propertyName);

        return (
            <ExternalSelect
                {...props}
                ref={ref}
                {...others}
                label={
                    (label || optional || iconDescription) && (
                        <InputLabel iconDescription={iconDescription} label={label} optional={optional} />
                    )
                }
            />
        );
    }
);

Select.displayName = 'Select';
